@import 'intl-tel-input/build/css/intlTelInput';
@import 'ngx-bootstrap/datepicker/bs-datepicker.css';
@import 'bootstrap/scss/bootstrap.scss';
@import 'ngx-toastr/toastr';
@import '@ng-select/ng-select/themes/default.theme.css';
@import 'apps/frontend/src/scss/variables';
@import 'apps/frontend/src/scss/form';
@import 'apps/frontend/src/scss/form-my-amica';
@import 'apps/frontend/src/scss/modal';
@import 'apps/frontend/src/scss/typography';
@import 'apps/frontend/src/scss/buttons';

html {
  scroll-behavior: smooth;
}

img.ng-lazy {
  transition: opacity 1s;
  opacity: 0;

  &.ng-lazyloaded {
    opacity: 1;
  }
}

@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1096px !important;
  }
}

#toast-container.toast-top-right {
  top: 82px;
}

.ngx-toastr.toast-error {
  background-color: $color_red;
}

.arrow-down {
  display: block;
  margin: 10px auto;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 12px solid $color_green;
}

.overlay {
  z-index: 99999;
  position: fixed;
  opacity: 1;
}
