@import 'variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

@font-face {
  font-family: 'Uni Neue';
  src: url('../assets/fonts/Fontfabric - UniNeueThin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Uni Neue';
  src: url('../assets/fonts/Fontfabric - UniNeueLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Uni Neue';
  src: url('../assets/fonts/Fontfabric - UniNeueBook.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Uni Neue';
  src: url('../assets/fonts/Fontfabric - UniNeueRegular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Uni Neue';
  src: url('../assets/fonts/Fontfabric - UniNeueBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Uni Neue';
  src: url('../assets/fonts/Fontfabric - UniNeueBlack.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: 'Uni Neue', sans-serif;
}

h2,
h3,
p {
  letter-spacing: 0;
  font-weight: normal;
}

h1 {
  font-size: 80px;
  line-height: 96px;

  @include media-breakpoint-down(md) {
    font-size: 46px;
    line-height: 60px;
  }
}

h2 {
  font-size: 46px;
  line-height: 56px;

  @include media-breakpoint-down(md) {
    font-size: 36px;
    line-height: 46px;
  }
}

h3 {
  font-size: 40px;
  line-height: 50px;

  @include media-breakpoint-down(lg) {
    font-size: 36px;
    line-height: 46px;
  }

  @include media-breakpoint-down(md) {
    font-size: 27px;
    line-height: 32px;
  }
}

h4 {
  font-size: 28px;
  line-height: 37px;
}

p {
  margin-bottom: 0;

  &.p-120 {
    font-size: 120px;
    line-height: 132px;

    @include media-breakpoint-down(lg) {
      font-size: 90px;
      line-height: 96px;
    }

    @include media-breakpoint-down(md) {
      font-size: 60px;
      line-height: 66px;
    }
  }

  &.p-60 {
    font-size: 60px;
    line-height: 50px;

    @include media-breakpoint-down(lg) {
      font-size: 46px;
    }

    @include media-breakpoint-down(md) {
      font-size: 36px;
    }
  }

  &.p-40 {
    font-size: 40px;
    line-height: 50px;

    @include media-breakpoint-down(lg) {
      font-size: 35px;
      line-height: 44px;
    }

    @include media-breakpoint-down(md) {
      font-size: 30px;
      line-height: 40px;
    }
  }

  &.p-35 {
    font-size: 35px;
    line-height: 44px;

    @include media-breakpoint-down(lg) {
      font-size: 30px;
      line-height: 40px;
    }

    @include media-breakpoint-down(md) {
      font-size: 27px;
      line-height: 36px;
    }
  }

  &.p-30 {
    font-size: 30px;
    line-height: 40px;

    @include media-breakpoint-down(lg) {
      font-size: 27px;
      line-height: 36px;
    }

    @include media-breakpoint-down(md) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &.p-28 {
    font-size: 28px;
    line-height: 36px;

    @include media-breakpoint-down(lg) {
      font-size: 24px;
      line-height: 32px;
    }

    @include media-breakpoint-down(md) {
      font-size: 21px;
      line-height: 27px;
    }
  }

  &.p-25 {
    font-size: 25px;
    line-height: 30px;

    @include media-breakpoint-down(lg) {
      font-size: 21px;
      line-height: 32px;
    }

    @include media-breakpoint-down(md) {
      font-size: 18px;
      line-height: 27px;
    }
  }

  &.p-24 {
    font-size: 24px;
    line-height: 36px;

    @include media-breakpoint-down(lg) {
      font-size: 21px;
      line-height: 32px;
    }

    @include media-breakpoint-down(md) {
      font-size: 18px;
      line-height: 27px;
    }
  }

  &.p-22 {
    font-size: 22px;
    line-height: 30px;

    @include media-breakpoint-down(md) {
      font-size: 18px;
      line-height: 27px;
    }
  }

  &.p-20 {
    font-size: 20px;
    line-height: 32px;

    @include media-breakpoint-down(md) {
      font-size: 16px;
      line-height: 22px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &.p-18 {
    font-size: 18px;
    line-height: 28px;

    @include media-breakpoint-down(md) {
      font-size: 16px;
      line-height: 22px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 14px;
      line-height: 19px;
    }
  }

  &.p-16 {
    font-size: 16px;
    line-height: 22px;

    @include media-breakpoint-down(md) {
      font-size: 13px;
      line-height: 19px;
    }
  }

  &.p-14 {
    font-size: 14px;
    line-height: 20px;

    @include media-breakpoint-down(md) {
      font-size: 12px;
      line-height: 18px;
    }
  }

  &.p-12 {
    font-size: 12px;
    line-height: 20px;

    @include media-breakpoint-down(md) {
      font-size: 9px;
      line-height: 16px;
    }
  }
}

.f-lato {
  font-family: 'Lato', sans-serif;
}

.f-uni {
  font-family: 'Uni Neue', sans-serif;
}

.white {
  color: #fff;
}

.c-text {
  color: $color_text;
}

.c-light-gray {
  color: $color_light-gray;
}

.c-text-light {
  color: $color_text-light;
}

.c-green {
  color: $color_green;
}

.c-light-blue {
  color: $color_light_blue;
}

.c-orange {
  color: $color_orange;
}

.c-red {
  color: $color_red;
}
