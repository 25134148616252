@import 'variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.amc-form {
  &.bigger {
    label {
      font-size: 18px;
      line-height: 24px;
    }

    textarea,
    input[type='text'],
    input[type='password'],
    input[type='number'] {
      font-size: 19px;
      line-height: 26px;
      padding: 18px 20px;
    }
  }

  .vmc-error {
    color: $color_red;
    font-size: 13px;
    font-weight: bold;
    padding-top: 4px;
  }

  textarea,
  input[type='text'],
  input[type='password'],
  input[type='number'],
  label {
    color: $color_text;
    font-size: 14px;
    width: 100%;
    letter-spacing: 0;
    line-height: 19px;
  }

  label {
    font-weight: bold;
    text-align: left;
  }

  textarea,
  input[type='text'],
  input[type='password'],
  input[type='number'] {
    padding: 13px 20px;
    border: 2px solid $color_light-gray;
    border-radius: 26px;
    outline: none;

    &:disabled,
    &:focus {
      border: 2px solid $color_text;
    }
  }

  input[type='checkbox'] {
    &:not(:checked),
    &:checked {
      position: absolute;
      left: -9999px;

      & + label {
        position: relative;
        padding-left: 40px;
        cursor: pointer;
        line-height: 1;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1.55em;
          height: 1.55em;
          border: 2px solid $color_light-gray;
          background: #fff;
          border-radius: 4px;
          box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
        }

        &:after {
          content: '\2713\0020';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 5px;
          font-size: 0.9rem;
          line-height: 0.7;
          color: $color_red;
          transition: all 0.2s;
          font-weight: bold;
        }
      }
    }

    &:not(:checked) + label:after {
      opacity: 0;
    }

    &:checked + label:after {
      opacity: 1;
    }

    &:disabled:checked + label:after {
      color: #999;
    }

    &:disabled + label {
      color: #aaa;
      cursor: not-allowed;

      &:before {
        box-shadow: none;
        border-color: #bbb;
        background-color: #ddd;
      }
    }

    &:checked:focus,
    &:not(:checked):focus {
      & + label:before {
        border: 2px solid $color_red;
      }
    }
  }

  .ng-select {
    &.ng-select-disabled {
      opacity: 0.3;
    }

    .ng-arrow-wrapper {
      display: none;
    }

    .ng-clear {
      color: $color_red;
      font-weight: bold;
    }

    .ng-dropdown-panel {
      border-color: $color_red;
    }

    &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
      border-color: $color_red;
    }

    &.ng-select-opened > .ng-select-container {
      border: 2px solid $color_red;
    }

    .ng-select-container {
      min-height: 80px;
      border: 2px solid #a7a7a7;
      border-radius: 20px;
    }
  }
}
