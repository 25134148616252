@import 'variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.amc-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 45px;
  box-shadow: 0 3px 6px #00000029;

  button.close-btn {
    background: transparent;
    font-size: 31px;
    line-height: 1;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    outline: none;
    transition: opacity 0.2s ease-in-out;

    &:focus {
      box-shadow: 0 0 3px 2px $color_red-am;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
}

.amc-override {
  .modal-content {
    background: transparent;
    border: none;
  }

  @include media-breakpoint-up(sm) {
    .modal-sm {
      max-width: 536px;
    }
  }
}
