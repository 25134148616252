$color_red: #e41429;
$color_red-2: #c73434;
$color_red-am: #e41429;
$color_maroon: #771d1d;
$color_orange: #dfa408;
$color_yellow: #ffe59f;
$color_green: #0b7c7c;
$color_light_green: #5ab2b2;
$color_blue: #4e4ee4;
$color_light_blue: #55b6b6;
$color_text: #222629;
$color_text-light: #717171;
$color-gray: #838282;
$color_light-gray: #a7a7a7;
$color_lighter-gray: #dedede;
