@import 'variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.amc-my-form {
  .form-group {
    &.position-relative {
      z-index: 123;
    }
  }

  .vmc-error {
    color: $color_red;
    font-size: 13px;
    font-weight: bold;
    padding-top: 4px;
  }

  textarea,
  input[type='text'],
  input[type='password'],
  input[type='number'] {
    font-family: 'Lato', sans-serif;
    color: $color_text;
    font-size: 14px;
    width: 100%;
    letter-spacing: 0;
    line-height: 19px;
    font-weight: 300;

    &.ng-invalid.ng-touched {
      border: 1px solid $color_red;
    }
  }

  label {
    font-weight: normal;
    text-align: left;
    font-family: 'Lato', sans-serif;
    color: $color_text;
    font-size: 16px;
    width: 100%;
    letter-spacing: 0;
    line-height: 19px;

    small {
      font-size: 12px;
      line-height: 17px;
      color: $color_text-light;
      font-weight: 300;
    }
  }

  textarea,
  input[type='text'],
  input[type='password'],
  input[type='number'] {
    padding: 10px;
    border: 1px solid $color_light-gray;
    border-radius: 10px;
    outline: none;

    &:disabled,
    &:focus {
      border: 1px solid $color_text;
    }
  }

  label.radio {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    margin: 12px 0;
    cursor: pointer;
    position: relative;

    .design {
      width: 24px;
      height: 24px;
      border: 2px solid $color_light-gray;
      border-radius: 100%;
      margin-right: 16px;
      position: relative;

      &::before,
      &::after {
        content: '';
        display: block;
        width: inherit;
        height: inherit;
        border-radius: inherit;
        position: absolute;
        transform: scale(0);
        transform-origin: center center;
        left: -2px;
        top: -2px;
      }

      &:before {
        background: $color_light-gray;
        opacity: 0;
        transition: 0.2s;
        position: absolute;
      }

      &::after {
        background: $color-red;
        opacity: 0.4;
        transition: 0.4s;
      }
    }

    input[type='radio'] {
      opacity: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;

      &:checked {
        & + .design {
          &::before {
            opacity: 1;
            background: $color_red;
            transform: scale(0.6);
          }
        }
      }

      &:focus {
        & + .design {
          border: 2px solid $color_red;
        }
      }
    }
  }

  input[type='checkbox'] {
    position: absolute;
    left: -9999px;

    & + label {
      position: relative;
      padding-left: 40px;
      cursor: pointer;
      margin: 12px 0;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        border: 2px solid $color_light-gray;
        background: #fff;
        border-radius: 4px;
        transition: 0.2s;
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
      }

      &:after {
        content: '\2713\0020';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 6px;
        font-size: 16px;
        line-height: 1;
        color: $color_red;
        transition: all 0.2s;
        font-weight: bold;
      }
    }

    &:not(:checked) + label:after {
      opacity: 0;
    }

    &:checked + label:after {
      opacity: 1;
    }

    &:disabled:checked + label:after {
      color: #999;
    }

    &:disabled + label {
      color: #aaa;
      cursor: not-allowed;

      &:before {
        box-shadow: none;
        border-color: #bbb;
        background-color: #ddd;
      }
    }

    &:checked:focus,
    &:not(:checked):focus {
      & + label:before {
        border: 2px solid $color_red;
      }
    }
  }

  .ng-select {
    &.ng-invalid.ng-touched {
      .ng-select-container {
        border: 1px solid $color_red;
      }
    }

    &.ng-select-disabled {
      opacity: 0.3;
    }

    .ng-arrow-wrapper {
      display: none;
    }

    .ng-clear {
      color: $color_light-gray;
      font-weight: bold;
    }

    .ng-dropdown-panel {
      border-color: $color_light-gray;
    }

    &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
      border-color: $color_light-gray;
    }

    &.ng-select-opened > .ng-select-container {
      border: 1px solid $color_text;
    }

    .ng-placeholder {
      font-size: 14px;
    }

    .ng-select-container {
      border: 1px solid #a7a7a7;
      border-radius: 10px;
      min-height: 41px;

      .ng-value-container {
        .ng-input {
          top: 8px;
        }
      }
    }
  }

  ngx-intl-tel-input {
    z-index: 1234;

    &.ng-invalid.ng-touched {
      .amm-ngx-intl-tel-input {
        border: 1px solid $color_red;
      }
    }

    .amm-ngx-intl-tel-input {
      padding: 10px 20px 10px 52px;
      border: 1px solid $color_light-gray;
      border-radius: 12px;
      outline: none;
      width: 100%;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;

      &:focus {
        border: 1px solid #000;
        box-shadow: none;
      }
    }

    div.iti {
      width: 100%;
    }

    .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }

    .dropdown-menu .search-container {
      input#country-search-box {
        padding: 12px 20px;
      }
    }
  }
}

div.ngb-dp-day,
div.ngb-dp-weekday {
  color: $color-text;
  font-family: 'Lato', sans-serif;
  font-style: normal;
}

div.ngb-dp-day {
  .bg-primary {
    background-color: $color_red !important;
  }
}
