@import 'variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

button,
a {
  border: none;

  &.button-red {
    background-color: $color_red;
    color: #fff;
    border-radius: 35px;

    @include media-breakpoint-down(md) {
      padding: 10px 14px;
    }

    &:not(:disabled):hover {
      text-decoration: none;
      color: $color_red;
      background-color: #fff;
    }
  }

  &.button-green {
    background-color: $color_green;
    color: #fff;
    border-radius: 35px;

    @include media-breakpoint-down(md) {
      padding: 10px 14px;
    }

    &:not(:disabled):hover {
      text-decoration: none;
      color: $color_green;
      background-color: #fff;
    }
  }

  &.button-white {
    background-color: #fff;
    color: $color_red;
    border-radius: 35px;

    @include media-breakpoint-down(md) {
      padding: 10px 14px;
    }

    &:not(:disabled):hover {
      text-decoration: none;
      color: #fff;
      background-color: $color_red;
    }
  }

  &.button-transparent {
    background-color: transparent;
    color: $color_red;
    border-radius: 35px;

    @include media-breakpoint-down(md) {
      padding: 10px 14px;
    }

    &:not(:disabled):hover {
      text-decoration: none;
      color: #fff;
      background-color: $color_red;
    }
  }

  &.button {
    transition: all 0.2s ease-in-out;
    font-size: 16px;
    letter-spacing: 0.35px;
    text-align: center;
    display: inline-block;
    box-shadow: 0 3px 6px #00000029;

    &:focus {
      outline: none;
      box-shadow: 0 0 3px 2px $color_red-am;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  &.button-big {
    min-width: 100%;
    padding: 10px 38px;
    line-height: 35px;
    font-size: 20px;

    @include media-breakpoint-down(md) {
      padding: 10px 38px;
    }
  }

  &.button-normal {
    min-width: 175px;
    padding: 10px 38px;
    line-height: 35px;

    @include media-breakpoint-down(md) {
      padding: 5px 14px;
    }
  }

  &.button-small {
    min-width: 192px;
    padding: 16px 22px;
    line-height: 8px;

    @include media-breakpoint-down(md) {
      padding: 12px 11px;
    }
  }

  &.button-tiny {
    padding: 8px 12px;
    line-height: 8px;
  }

  &.am-border-white {
    border: 2px solid #fff;
    &:not(:disabled):hover {
      border: 2px solid $color_red;

      &.button-green {
        border: 2px solid $color_green;
      }
    }
  }

  &.am-border-red {
    border: 2px solid $color_red;
    &:not(:disabled):hover {
      border: 2px solid #fff;
    }
  }
}
